import React, { useState, useEffect } from 'react';
import { FaTimes, FaSearch, FaSignOutAlt } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import './CustomSelectBox.css';
import { getReportsUser } from '../../services/getReportsUser';
import { CustomSelectBoxProps } from '../../interfaces/customSelectBox';
import { routesPrefeitura, routesInternalBase, routeGestor, routeLider } from '../../config/routers';
import { Route } from '../../interfaces/customSelectBox';

const CustomSelectBox: React.FC<CustomSelectBoxProps> = ({
    onRouteSelected,
    show = false,
    onClose,
    isButtonVisible = true,
    showCloseButton = true,
    onLogout,
    showLogoutButton = false
}) => {
    const [selectedRoute, setSelectedRoute] = useState<string>("");
    const [activeTab, setActiveTab] = useState<string>('');
    const [filteredRoutes, setFilteredRoutes] = useState<Route[]>([]);
    const [internalShow, setInternalShow] = useState<boolean>(false);
    const [acessoPowerBi, setAcessoPowerBi] = useState<string[]>([]);
    const [isGestor, setIsGestor] = useState<boolean>(false);
    const [isLider, setIsLider] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchPowerBIReports = async () => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                try {
                    const data = await getReportsUser(token);
                    const { acesso_powerbi, adm, gestor, lider } = data;
                    const access = adm ? ["adm"] : (acesso_powerbi || []);
                    setAcessoPowerBi(access);
                    setIsGestor(!!gestor);
                    setIsLider(!gestor && !!lider);  

                    const initialTab = determineInitialTab(access, !!gestor, !!lider);
                    setActiveTab(initialTab);
                } catch (error) {
                    console.error('Erro ao buscar os dados de acesso Power BI:', error);
                }
            }
        };

        fetchPowerBIReports();
    }, []);

    useEffect(() => {
        filterRoutes();
    }, [location.pathname, acessoPowerBi, activeTab, isGestor, isLider]);

    const determineInitialTab = (access: string[], gestor: boolean, lider: boolean) => {
        if ((gestor || lider) && access.length === 0) return 'routesInternal';
        if (access.includes("adm")) return 'routesPrefeitura';
        if (routesPrefeitura.some(route => access.includes(route.path.slice(1)))) return 'routesPrefeitura';
        if (routesInternalBase.some(route => access.includes(route.path.slice(1))) || gestor || lider) return 'routesInternal';
        return 'routesPrefeitura';
    };

    const handleTabChange = (tabName: string) => {
        setActiveTab(tabName);
    };

    const filterRoutes = () => {
        const isAdmin = acessoPowerBi.includes("adm");

        let routes = isAdmin ? (activeTab === 'routesPrefeitura' ? routesPrefeitura : [...routesInternalBase, ...routeGestor]) :
            (activeTab === 'routesPrefeitura' ? routesPrefeitura.filter(route => acessoPowerBi.includes(route.path.slice(1))) :
            routesInternalBase.filter(route => acessoPowerBi.includes(route.path.slice(1))));

        if (isGestor && activeTab === 'routesInternal') {
            routes = [...routes, ...routeGestor];
        } else if (isLider && activeTab === 'routesInternal') {
            const gerenciaExecucaoRoute = routeLider.find(route => route.path === '/gerencia-execucao');
            if (gerenciaExecucaoRoute) {
                routes = [...routesInternalBase, gerenciaExecucaoRoute];
            }
        }

        setFilteredRoutes(routes);
    };

    const handleClose = () => {
        setInternalShow(false);
        onClose?.();
    };

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        const filtered = filteredRoutes.filter(route =>
            route.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(searchValue)
        );
        setFilteredRoutes(filtered.length > 0 ? filtered : []);
    };

    const handleSelectRoute = (path: string, name: string) => {
        onRouteSelected(path);
        setSelectedRoute(name);
        handleClose();
    };

    const toggleShow = () => {
        setInternalShow(!internalShow);
    };

    const displayBox = isButtonVisible ? internalShow : show;

    const hasAccess = filteredRoutes.length > 0;
    const hasPrefeituraAccess = routesPrefeitura.some(route => acessoPowerBi.includes(route.path.slice(1))) || acessoPowerBi.includes("adm");
    const hasInternalAccess = routesInternalBase.some(route => acessoPowerBi.includes(route.path.slice(1))) || acessoPowerBi.includes("adm") || isGestor || isLider;

    return (
        <div className='mb-4'>
            {isButtonVisible && (
                <button className="route-selected" onClick={toggleShow}>
                    {selectedRoute || "Selecione aqui"}
                </button>
            )}
            {displayBox && (
                <div className="custom-select-box">
                    {hasPrefeituraAccess || hasInternalAccess ? (
                        <>
                            <div className="tabs-container">
                                <div className="tabs">
                                    {hasPrefeituraAccess && (
                                        <button
                                            onClick={() => handleTabChange('routesPrefeitura')}
                                            className={`tab ${activeTab === 'routesPrefeitura' ? 'active tab-bold' : ''}`}>
                                            Controle das Prefeituras
                                        </button>
                                    )}
                                    {hasInternalAccess && (
                                        <button
                                            onClick={() => handleTabChange('routesInternal')}
                                            className={`tab ${activeTab === 'routesInternal' ? 'active tab-bold' : ''}`}>
                                            Relatórios Internos
                                        </button>
                                    )}
                                </div>
                                {showLogoutButton && onLogout && (
                                    <button onClick={onLogout} className="logout-button" title="Sair">
                                        <FaSignOutAlt />
                                    </button>
                                )}
                            </div>
                            <div className="search-button-box">
                                <div className="search-input-container">
                                    <input type="text" onChange={handleFilter} placeholder="Pesquisar Power BI..." />
                                    <FaSearch className="search-icon" />
                                </div>
                                {showCloseButton && (
                                    <button onClick={handleClose} className="close-button">
                                        <FaTimes />
                                    </button>
                                )}
                            </div>
                            <div className="mt-2 routes-list">
                                {filteredRoutes.map(route => (
                                    <div className="routes-bi" key={route.id} onClick={() => handleSelectRoute(route.path, route.name)}>
                                        {route.name}
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <div className="no-access-message-container">
                            <div className="no-access-message">
                                Você não possui acesso a nenhum Power BI.
                            </div>
                            {showLogoutButton && onLogout && !hasAccess && (
                                <button onClick={onLogout} className="logout-button-no-access" title="Sair">
                                    Retornar a página inicial <FaSignOutAlt />
                                </button>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CustomSelectBox;
