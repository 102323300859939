import { environment } from '../config/environments';
import axios from 'axios';

export const getReportsUser = async (token: string) => {
  try {
    const response = await axios.get(`${environment.apiIntraNet}/powerbi/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar os dados de acesso Power BI:', error);
    throw error;
  }
};
