import axios from "axios";
import { environment } from "../config/environments";

const ldap = axios.create({
  baseURL: environment.apiIntraNet,
});

export async function refreshAccessToken(refreshToken: string): Promise<string> {
  try {
    const response = await ldap.post("/auth/refresh", { refreshToken });
    return response.data.accessToken;
  } catch (error) {
    throw new Error("Failed to refresh access token");
  }
}

export async function authLdap(username: string, password: string) {
  try {
    const response = await ldap.post("/auth/login", { username, password });
    return response.data;
  } catch (error) {
    throw error;
  }
}