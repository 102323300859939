import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomSelectBox from '../../components/CustomSelectBox/CustomSelectBox';
import './Menu.css';

const Menu: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') || sessionStorage.getItem('isLoggedIn');

        if (!isLoggedIn) {
            navigate('/login');
            return;
        }

        document.body.style.backgroundImage = "url('/images/background-menu.png')";
        document.body.style.backgroundSize = "cover";
        document.body.style.backgroundPosition = "center";

        return () => {
            document.body.style.backgroundImage = "";
            document.body.style.backgroundSize = "";
            document.body.style.backgroundPosition = "";
        };
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn');
        sessionStorage.removeItem('isLoggedIn');
        localStorage.removeItem('lastRoute');
        sessionStorage.removeItem('lastRoute');
    
        navigate('/login')
      }

    const handleChangeRoute = (route: string) => {
        navigate(route);
    };

    return (
        <div className="menu">
            <div className='menu-select-bi'>
                <CustomSelectBox 
                    onLogout={handleLogout}
                    onRouteSelected={handleChangeRoute}
                    show={true}
                    isButtonVisible={false}
                    showCloseButton={false}
                    showLogoutButton={true}
                />
            </div>
        </div>
    );
};

export default Menu;
