import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getReportsUser } from '../../../../services/getReportsUser'; 
import { environment } from '../../../../config/environments';
import './Print.css'; 
import { FaFilePdf } from 'react-icons/fa';

const RelatorioCusto: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkPermission = async () => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        try {
          const data = await getReportsUser(token);
          const { adm, gestor } = data;
          if (!adm && !gestor) {
            navigate('/login');
          }
        } catch (error) {
          console.error('Erro ao verificar permissões:', error);
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
    };

    checkPermission();
  }, [navigate]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="print-container">
      <iframe
        src={environment.urlPowerBiRelatorioExtrato}
        className="print-iframe"
        frameBorder="0"
        allowFullScreen={true}
        title="Relatório de Custo"
      ></iframe>
      <button className="print-button" onClick={handlePrint}>
        <FaFilePdf size={24} color="#fff" />
      </button>
    </div>
  );
};

export default RelatorioCusto;
