import React, { useEffect, useState } from "react";
import jwt from 'jsonwebtoken';
import { useNavigate } from 'react-router-dom';
import { authLdap } from "../../services/authLdap";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './Login.css'

interface LoginProps {
  onLogin: (route: string) => void;
}

function Login({ onLogin }: LoginProps) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (showError) {
      timer = setTimeout(() => {
        setShowError(false);
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [showError]);

  useEffect(() => {
    const isLoggedInSession = sessionStorage.getItem('isLoggedIn');
    const isLoggedInLocal = localStorage.getItem('isLoggedIn');

    if (isLoggedInLocal || (isLoggedInSession && !rememberMe)) {
      onLogin('/menu');
      navigate('/menu');
    }
  }, [onLogin, navigate, rememberMe]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (!username || !password) {
      setError('Por favor, insira seu nome de usuário e senha para continuar.');
      setShowError(true);
      return;
    }

    try {
      const authData = await authLdap(username, password);
      const { accessToken, refreshToken } = authData;

      localStorage.setItem('accessToken', accessToken);
      sessionStorage.setItem('refreshToken', refreshToken);

      if (rememberMe) {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('username', username);
        sessionStorage.removeItem('isLoggedIn');
      } else {
        sessionStorage.setItem('isLoggedIn', 'true');
        sessionStorage.setItem('username', username);
        localStorage.removeItem('isLoggedIn');
      }
      
      onLogin('/menu');
      navigate('/menu');
    } catch (error) {
      setError('Usuário ou senha incorretos.');
      setShowError(true);
    }
  };

  return (
    <div className="login">
      <div className="image-background" />
      <div className="login-container">
        <h2>Login</h2>
        <div className="input-group">
          <label htmlFor="username">Usuário</label>
          <div className="input-container">
            <input
              id="username"
              type="text"
              placeholder="Insira seu usuário"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </div>
        </div>
        <br />
        <div className="input-group">
          <label htmlFor="password">Senha</label>
          <div className="input-container password-container">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Insira sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <span onClick={() => setShowPassword(!showPassword)} className="toggle-password">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>
        <br />
        <div className="input-remember-me">
          <input
            type="checkbox"
            id="rememberMe"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label htmlFor="rememberMe">Lembrar-me</label>
        </div>
        <button className="login-button" onClick={handleLogin}>LOGIN</button>
        <div className="espaco-vazio" />
        {error && <div className={`error-message ${showError ? 'show' : ''}`}>{error}</div>}
        <img className="topocart-logo" src="/images/topocart-logo.png" alt="" />
      </div>
    </div>
  );
}

export default Login;
